import * as React from 'react';
import { Link, useTranslation} from 'gatsby-plugin-react-i18next';
import slugify from '@sindresorhus/slugify';

const ResourcesFilter = ({ data }) => {
  
  const {t} = useTranslation();
  
  return (
    <>
      {data.topics.group.length ?
        <div key="topics" className="mb-7">
          <div className="mb-3">
            <h3>{t('topics')}</h3>
          </div>
          {data.topics.group.map(topic => (
            <Link key={topic.title} to={`/resources/topics/${slugify(topic.title)}/`} className="btn btn-soft-secondary btn-xs rounded-pill me-1 mb-1" activeClassName="active">
              {t('resource.topics.' + topic.title + '.label')} <span className="badge bg-light rounded-pill text-dark m-1">{topic.totalCount}</span>
            </Link>
          ))}
        </div>
      : null}
      {data.types.group.length ?
        <div key="types" className="mb-7">
          <div className="mb-3">
            <h3>{t('types')}</h3>
          </div>
          {data.types.group.map(type => (
            <Link key={type.title} to={`/resources/types/${slugify(type.title)}/`} className="btn btn-soft-secondary btn-xs rounded-pill me-1 mb-1" activeClassName="active">
              {t('resource.types.' + type.title + '.label')} <span className="badge bg-light rounded-pill text-dark m-1">{type.totalCount}</span>
            </Link>
          ))}
        </div>
      : null} 
    </>
  )
}

export default ResourcesFilter